import React from "react";
import styled from "styled-components";
import ReactPlayer from 'react-player';

const StyledVideo = styled.div`
display:flex;
justify-content : center;
iframe {
    width: 100%;
    height: 400px;
  }
`;

const Video = ({ videoSrcURL, poster, playing }) => (
  <StyledVideo>
    <ReactPlayer
      url={videoSrcURL}
      config={ { file: { attributes: { poster } } } }
      controls
      //width={"100%"}
      playing={playing}
    />
  </StyledVideo>
);
export default Video;
